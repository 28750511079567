import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../../helpers/constants";
import { getTrueClasses } from "../../../helpers";
import HomeIntroPoints from "./HomeIntroPoints";
import StandardFormBlockContainer from "../../../containers/common/forms/StandardFormBlockContainer";
import HomeIntroScreen from "./HomeIntroScreen";
import "./index.scss";

const HomeIntro = (props) => {
  const {
    isSecondStateCollectEmail,
    setIsSecondStateCollectEmail,
    isLoading,
    onSuccess,
  } = props;
  return (
    <section
      className={getTrueClasses(
        "PgOA-HomeIntro",
        isSecondStateCollectEmail && "PgOA-HomeIntro-secondState"
      )}
    >
      <div className="container">
        <h1 className="PgOA-HomeIntro__h1">
          Amazon Dropshipping, Arbitrage, and Wholesale Product Research
          Software
        </h1>
        <div className="PgOA-HomeIntro-row">
          <div className="PgOA-HomeIntro-info">
            <HomeIntroPoints />
            <div className="PgOA-HomeIntro__subTitle">
              Try Amazon Dropshipping, Arbitrage, and Wholesale Extension For
              Free Now
            </div>
            <StandardFormBlockContainer
              pageFormState={isSecondStateCollectEmail}
              updatePageFormState={setIsSecondStateCollectEmail}
              isLoading={isLoading}
              buttonTextFirstState="Try AMZScout For Free!"
              buttonTextSecondState="TRY FOR FREE NOW"
              page={PAGES.ONLINE_ARBITRAGE}
              customClass="PgOA-HomeIntro-emailCollect"
              placeholder="Enter your email to start your FREE TRIAL!"
              onSuccess={onSuccess}
              isIntro
            />
          </div>
          <HomeIntroScreen />
        </div>
      </div>
    </section>
  );
};

HomeIntro.propTypes = {
  isLoading: PropTypes.bool,
  isSecondStateCollectEmail: PropTypes.bool,
  setIsSecondStateCollectEmail: PropTypes.func,
};

export default HomeIntro;
