import React from "react";
import FIND_CONTENT from "./index.content";
import Screenshot from "../Screenshot";
import "./index.scss";

const Find = () => {
  const { firstScreenshot, secondScreenshot, thirdScreenshot } = FIND_CONTENT;
  return (
    <section className="PgOA-Find">
      <div className="container">
        <div className="PgOA-Find-block">
          <div className="PgOA-Find-text">
            <div className="PgOA-Find-text__title">
              Find great products while simply browsing the Amazon search page
            </div>
            You can simply scroll Amazon search results while checking Margin,
            Fees and Seller Types. The app will show all of that data underneath
            each product.
          </div>
          <Screenshot
            customClasses="PgOA-Find-screen"
            listPictures={firstScreenshot}
          />
        </div>
        <div className="PgOA-Find-block">
          <div className="PgOA-Find-text">
            Place your mouse cursor over the AMZScout logo in the top-left
            corner of the product image and the tool will show additional info,
            including <b>price history</b> and the <b>main keywords</b> that it
            is ranked for
          </div>
          <Screenshot
            customClasses="PgOA-Find-screen"
            listPictures={secondScreenshot}
          />
        </div>
        <div className="PgOA-Find-block">
          <div className="PgOA-Find-text">
            <div>
              With these insights, you can scroll through search results and{" "}
              <b>dismiss products that don’t fit your criteria</b> without
              having to open the product page. This allows you to scan up to 5
              times more products and{" "}
              <b>find more profitable products in less time!</b>
            </div>
            Once you find a promising product, you can go to its page and find
            out how much potential profit it offers by using an in-built
            calculator
          </div>
          <Screenshot
            customClasses="PgOA-Find-screen"
            listPictures={thirdScreenshot}
          />
        </div>
      </div>
    </section>
  );
};

export default Find;
