import React from "react";
import STUDY_CONTENT from "./index.content";
import Items from "../Items";
import Screenshot from "../Screenshot";
import "./index.scss";

const Study = () => {
  const { screenshot, SVG_PointIcon, listTexts } = STUDY_CONTENT;

  return (
    <section className="PgOA-Study">
      <div className="container">
        <div className="PgOA-Study-info">
          <div className="PgOA-Study-points">
            <div className="PgOA-Study-points__title">
              Review market trends to identify profitable products
            </div>
            <Items
              pic={SVG_PointIcon}
              listTexts={listTexts}
              customClasses="PgOA-Study-points__item"
            />
          </div>
          <Screenshot
            customClasses="PgOA-Study-screen"
            listPictures={screenshot}
          />
        </div>
      </div>
    </section>
  );
};
export default Study;
