import PNG_ONE from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-1.png";
import PNG_ONE_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-1@2x.png";
import PNG_ONE_Mob from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-1@mob.png";
import PNG_ONE_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-1@mob2x.png";
import WEBP_ONE from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-1.webp";
import WEBP_ONE_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-1@2x.webp";
import WEBP_ONE_Mob from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-1@mob.webp";
import WEBP_ONE_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-1@mob2x.webp";

import PNG_TWO from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2.png";
import PNG_TWO_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2@2x.png";
import PNG_TWO_Mob from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2@mob.png";
import PNG_TWO_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2@mob2x.png";
import WEBP_TWO from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2.webp";
import WEBP_TWO_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2@2x.webp";
import WEBP_TWO_Mob from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2@mob.webp";
import WEBP_TWO_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-2@mob2x.webp";

import PNG_THREE from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3.png";
import PNG_THREE_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3@2x.png";
import PNG_THREE_Mob from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3@mob.png";
import PNG_THREE_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3@mob2x.png";
import WEBP_THREE from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3.webp";
import WEBP_THREE_2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3@2x.webp";
import WEBP_THREE_Mob from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3@mob.webp";
import WEBP_THREE_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Find/oa-sec6-3@mob2x.webp";

const FIND_CONTENT = {
  firstScreenshot: {
    WEBP: WEBP_ONE,
    WEBP_2x: WEBP_ONE_2x,
    PNG: PNG_ONE,
    PNG_2x: PNG_ONE_2x,
    WEBP_Mob: WEBP_ONE_Mob,
    WEBP_Mob2x: WEBP_ONE_Mob2x,
    PNG_Mob: PNG_ONE_Mob,
    PNG_Mob2x: PNG_ONE_Mob2x,
  },
  secondScreenshot: {
    WEBP: WEBP_TWO,
    WEBP_2x: WEBP_TWO_2x,
    PNG: PNG_TWO,
    PNG_2x: PNG_TWO_2x,
    WEBP_Mob: WEBP_TWO_Mob,
    WEBP_Mob2x: WEBP_TWO_Mob2x,
    PNG_Mob: PNG_TWO_Mob,
    PNG_Mob2x: PNG_TWO_Mob2x,
  },
  thirdScreenshot: {
    WEBP: WEBP_THREE,
    WEBP_2x: WEBP_THREE_2x,
    PNG: PNG_THREE,
    PNG_2x: PNG_THREE_2x,
    WEBP_Mob: WEBP_THREE_Mob,
    WEBP_Mob2x: WEBP_THREE_Mob2x,
    PNG_Mob: PNG_THREE_Mob,
    PNG_Mob2x: PNG_THREE_Mob2x,
  },
}
export default FIND_CONTENT;
