import React from "react";
import PNG from "../../../assets/images/raster/pages/online-arbitrage/Study/oa-sec4-bg.png";
import PNG_2x from "../../../assets/images/raster/pages/online-arbitrage/Study/oa-sec4-bg@2x.png";
import PNG_Mob from "../../../assets/images/raster/pages/online-arbitrage/Study/oa-sec4-bg@mob.png";
import PNG_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Study/oa-sec4-bg@mob2x.png";
import WEBP from "../../../assets/images/raster/pages/online-arbitrage/Study/oa-sec4-bg.webp";
import WEBP_2x from "../../../assets/images/raster/pages/online-arbitrage/Study/oa-sec4-bg@2x.webp";
import WEBP_Mob from "../../../assets/images/raster/pages/online-arbitrage/Study/oa-sec4-bg@mob.webp";
import WEBP_Mob2x from "../../../assets/images/raster/pages/online-arbitrage/Study/oa-sec4-bg@mob2x.webp";
import SVG_PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";

const STUDY_CONTENT = {
  screenshot: {
    WEBP,
    WEBP_2x,
    PNG,
    PNG_2x,
    WEBP_Mob,
    WEBP_Mob2x,
    PNG_Mob,
    PNG_Mob2x
  },
  SVG_PointIcon,
  listTexts: [
    <><b>Monitor products’ rank</b> to see fluctuations in demand, predict market trends and select the most promising products for future</>,
    <><b>Review price history and fluctuations in demand</b> to predict marginality trends and develop the best repricing strategy</>,
  ]

};
export default STUDY_CONTENT;
